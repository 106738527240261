import * as React from 'react';
import Box from '@mui/material/Box';
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid';
import { useEffect } from 'react';

const columns: GridColDef<(typeof rows)[number]>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    hideable: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    width: 100,
  },
  {
    field: 'offerValue',
    headerName: 'Offer Value',
    width: 100,
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    sortable: false,
    width: 100,
    // valueGetter: (value, row) => `${row.mediaSpend || ''} ${row.lastName || ''}`,
  },
  {
    field: 'addToListEvent',
    headerName: 'Add To List Event',
    width: 130,
  },
  {
    field: 'redemptionEvent',
    headerName: 'Redemption Event',
    width: 130,
  },
  {
    field: 'redemptions',
    headerName: 'Redemptions',
    width: 110,
  },
  {
    field: 'redemptionRate',
    headerName: 'Redemption Rate',
    width: 120,
  },
  {
    field: 'avgDailyUnitsMoved',
    headerName: 'Avg. Daily Units Moved',
    width: 160,
  },
  {
    field: 'avgUnitsPerTrip',
    headerName: 'Avg. Units Per Trip',
    width: 140,
  },
];

const rows = [
  { id: 1, name: 'AOR New Purchasers', duration: '6/7 - 8/8', offerValue: '$1.00' },
  { id: 2, name: 'AOR Past Purchasers', duration: '6/7 - 8/8', offerValue: '$0.25' },
  { id: 3, name: 'Walmart New Purchasers', duration: '6/7 - 8/8', offerValue: '$1.50' },
  { id: 4, name: 'Walmart Past Purchasers', duration: '6/7 - 8/8', offerValue: '$0.25' },
  { id: 5, name: 'Walmart New Purchasers P2', duration: '6/7 - 8/8', offerValue: '$1.00' },
];

export default function DataGridDemoMUI(props: any) {
  const { data } = props;

  useEffect(() => {
    console.log(data['dataGrid']);
  }, []);

  const valueGetter = (params: GridCellParams) => {
    console.log(params);
  };

  return (
    <Box
      sx={{
        // height: 540,
        width: '100%',
        fontSize: '20px',
        '& .MuiDataGrid-footerContainer': { border: 'none' },
      }}
    >
      {data.dataGrid && (
        <DataGrid
          // rows={rows}
          rows={data['dataGrid']['rows']}
          // columns={dataGrid.columns}
          columns={data['dataGrid']['columns']}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowHeight={60}
          pageSizeOptions={[8]}
          checkboxSelection={false}
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell': {
              fontSize: '16px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '12px',
              color: '#999',
            },
            '& .MuiDataGrid-row:last-child': {
              // fontWeight: 'bold',
            },
          }}
          getCellClassName={(params: any) => {
            if (params.field === 'age') {
              return <div className={'bg-fuchsia-100 p-4'}>params.value;</div>;
            } else {
              return params.value;
            }
          }}
          columnVisibilityModel={{
            id: false,
          }}
        />
      )}
    </Box>
  );
}
