import MetricNode from './MetricNode';
import NoData from './NoData';
import TwoSimplePieChart from './PieChart';
import { chartPalette } from './Themes';
import { CenterCircleNode } from './CenterCircleNode';
import BarChart from './BarChart';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import DataGridDemoMUI from './DataGridDemoMUI';

export const CampaignTemplate01 = (props: any) => {
  const { selectedCampaign, isCampaignRendered } = props;

  return (
    <div className="grid mt-4 sm:mt-0">
      <div className="parent p-0 sm:p-4 mt-2">
        <div
          className={`div1 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6 ${selectedCampaign.cpm ? '' : 'expand-first-cell'}`}
        >
          {isCampaignRendered && selectedCampaign['mediaSpend'] ? (
            <MetricNode
              title="Media Spend"
              frontDef={'$'}
              metricValue={
                Number.isInteger(selectedCampaign.mediaSpend / 1000)
                  ? selectedCampaign.mediaSpend / 1000
                  : (selectedCampaign.mediaSpend / 1000).toFixed(1)
              }
              // metricValue={(selectedCampaign?.mediaSpend / 1000).toFixed(2)}
              backDef={'K'}
              trending={'up'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          ) : (
            <NoData />
          )}
        </div>
        {isCampaignRendered && selectedCampaign['cpm'] && (
          <div className="div2 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6">
            <MetricNode
              title="CPM"
              frontDef={'$'}
              metricValue={(Math.round(selectedCampaign['cpm'] * 100) / 100).toFixed(2)}
              backDef={''}
              trending={'up'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          </div>
        )}
        <div
          className={`div3 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6 ${!selectedCampaign?.reach && 'extend-cell'}`}
        >
          {isCampaignRendered && selectedCampaign['impressions'] ? (
            <MetricNode
              title="Impressions"
              frontDef={null}
              // metricValue={(selectedCampaign?.impressions / 1000000).toFixed(2)}
              metricValue={
                Number.isInteger(selectedCampaign.impressions / 1000000)
                  ? (selectedCampaign.impressions / 1000000).toFixed(0)
                  : (selectedCampaign.impressions / 1000000).toFixed(1)
              }
              backDef={'M'}
              trending={'down'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          ) : (
            <NoData />
          )}
        </div>
        {/*{selectedCampaign?.reach || selectedCampaign?.frequency ? null : <NoData />}*/}
        {selectedCampaign?.reach && (
          <div className="div4 bg-white rounded-xl shadow-md flex flex-row justify-center items-center relative">
            <MetricNode
              title="Reach"
              frontDef={null}
              metricValue={
                selectedCampaign['reach'] > 999999
                  ? selectedCampaign['reach'] / 1000000
                  : selectedCampaign['reach'] / 1000
              }
              backDef={selectedCampaign['reach'] > 999999 ? 'M' : 'K'}
              trending={'down'}
              size={'small'}
              isCampaignRendered={isCampaignRendered}
            />
            <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            {selectedCampaign?.frequency && (
              <MetricNode
                title="Frequency"
                frontDef={null}
                metricValue={selectedCampaign['frequency']}
                backDef={null}
                trending={'up'}
                size={'small'}
                isCampaignRendered={isCampaignRendered}
              />
            )}
          </div>
        )}

        <div className="div5 bg-white flex justify-center items-center rounded-xl shadow-md relative py-6">
          {isCampaignRendered ? (
            <div className="w-full h-full flex flex-col xl:flex-row justify-center items-center">
              <div className="absolute top-3 left-4 text-sm text-gray-500">
                {selectedCampaign.donutTitle ? selectedCampaign.donutTitle : 'Investment By Tactic'}
              </div>
              <div className="flex items-center justify-center sm:w-full md:w-full lg:w-full xl:w-1/2">
                <div className="absolute">
                  <div className="text-xl font-bold text-black mb-[-6px]">
                    {Math.round(selectedCampaign['impressions'] / 1000000)}M
                  </div>
                  <div className="text-[10px] text-gray-500">Impressions</div>
                </div>
                {selectedCampaign['donut'] && (
                  <TwoSimplePieChart data={selectedCampaign['donut']} palette={chartPalette} symbol={'%'} />
                )}
              </div>
              <div className="hidden lg:flex w-full md:w-[1px] h-2/3 bg-slate-200 my-4"></div>
              {selectedCampaign['donut'] && (
                <div className="flex flex-col w-full md:w-1/2 pl-12 sm:pl-6 lg:block xl:block">
                  {selectedCampaign['donut'].map((item: any, index: any) => {
                    return (
                      <div className="flex flex-row w-full my-1" key={index}>
                        <div>
                          <div
                            key={index}
                            className="w-[12px] bg-slate-200 m-[2px] rounded-[2px] h-full"
                            style={{
                              background: chartPalette[index],
                            }}
                          ></div>
                        </div>
                        <div className="flex text-left text-[10px] text-gray-500 w-2/4 ml-1 bg-gray-50 mr-1">
                          {item.label}
                        </div>
                        <div className="flex flex-row w-1/4 justify-end bg-gray-50 px-1 items-center">
                          <div className="text-[10px] text-gray-500">
                            {/*{Math.round(item.value / 1000)}K*/}
                            <NumericFormat value={item.value} displayType="text" thousandSeparator={true} />%
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
        <div className="div6 bg-white rounded-xl shadow-md flex p-8 items-center justify-center flex-col relative overflow-hidden">
          {isCampaignRendered && (
            <div className="absolute top-3 left-4 text-sm text-gray-500">Performance Analysis</div>
          )}
          {isCampaignRendered ? (
            <CenterCircleNode
              topRightCircVal={selectedCampaign['topRightCircVal']}
              topRightCircCur={selectedCampaign['topRightCircCur']}
              mainCircVal={selectedCampaign['mainCircVal']}
              mainCircCur={selectedCampaign['mainCircCur']}
              mainCircTitle={selectedCampaign?.mainCircTitle ? selectedCampaign?.mainCircTitle : 'Incremental ROAS'}
              bottomLeftCircVal={selectedCampaign['bottomLeftCircVal']}
              bottomCircleCur={selectedCampaign['bottomCircleCur']}
              rangeIndicators={true}
              frontDef={'$'}
              backDef={null}
            />
          ) : (
            <NoData />
          )}
          {isCampaignRendered ? (
            <div className="text-xs text-slate-500 -mb-8 mt-6 px-0 sm:px-12">
              <span className="text-5xl font-serif align-bottom inline-block mb-[-16px]">&ldquo;</span>
              {selectedCampaign['insight01']}
              <span className="text-5xl align-top ml-[2px] font-serif inline-block mt-1">&rdquo;</span>
            </div>
          ) : null}
        </div>
        <div className="div7 bg-white rounded-xl shadow-md flex px-8 items-center justify-center relative">
          {isCampaignRendered ? (
            <div className="text-xs text-slate-500 -mb-4">
              <span className="text-5xl font-serif align-bottom inline-block mb-[-16px]">&ldquo;</span>
              {selectedCampaign['insight02']}
              <span className="text-5xl align-top ml-[2px] font-serif inline-block mt-1">&rdquo;</span>
            </div>
          ) : (
            <NoData />
          )}
        </div>
        <div className="div8 bg-white rounded-xl shadow-md flex items-center justify-center relative">
          {selectedCampaign?.estimatedTotalSales ||
          selectedCampaign?.estimatedTotalROAS ||
          selectedCampaign?.totalBuyers ? null : (
            <div>
              <NoData />
            </div>
          )}
          {selectedCampaign?.estimatedTotalSales && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="Estimated Total Sales"
                  frontDef={'$'}
                  metricValue={
                    selectedCampaign['estimatedTotalSales'] < 999999
                      ? Number.isInteger(selectedCampaign?.estimatedTotalSales)
                        ? (selectedCampaign?.estimatedTotalSales / 1000).toFixed(0)
                        : (selectedCampaign?.estimatedTotalSales / 1000).toFixed(2)
                      : (selectedCampaign?.estimatedTotalSales / 1000000).toFixed(2)
                  }
                  backDef={selectedCampaign['estimatedTotalSales'] < 999999 ? 'K' : 'M'}
                  trending={'down'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
          {selectedCampaign?.estimatedTotalROAS && (
            <div className="w-full flex flex-col items-center justify-center relative h-full">
              <MetricNode
                title="Estimated Total ROAS"
                frontDef={'$'}
                metricValue={selectedCampaign['estimatedTotalROAS']}
                backDef={null}
                trending={'up'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            </div>
          )}
          {selectedCampaign?.totalBuyers && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="Total Buyers"
                  frontDef={'$'}
                  metricValue={Math.round(selectedCampaign['totalBuyers'] / 1000)}
                  backDef={'K'}
                  trending={'up'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
          {selectedCampaign?.attributedSales && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="Attributed Sales"
                  frontDef={'$'}
                  metricValue={Math.round(selectedCampaign['attributedSales'] / 1000)}
                  backDef={'K'}
                  trending={'up'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
        </div>
        <div className="div9 bg-white rounded-xl shadow-md flex items-center justify-center relative">
          {selectedCampaign?.salesLift ||
          selectedCampaign?.incrementalSales ||
          selectedCampaign?.incrementalROAS ? null : (
            <div>{/*<NoData />*/}</div>
          )}
          {selectedCampaign?.salesLift && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="Sales Lift"
                  frontDef={null}
                  metricValue={selectedCampaign['salesLift']}
                  backDef={'%'}
                  trending={'down'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
          {selectedCampaign?.incrementalSales && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="Incremental Sales"
                  frontDef={'$'}
                  metricValue={
                    Number.isInteger(selectedCampaign?.incrementalSales)
                      ? (selectedCampaign?.incrementalSales / 1000).toFixed(2)
                      : (selectedCampaign?.incrementalSales / 1000).toFixed(2)
                  }
                  backDef={'K'}
                  trending={'up'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
          {selectedCampaign?.cpa && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title="CPA"
                  frontDef={'$'}
                  metricValue={(Math.round(selectedCampaign['cpa'] * 100) / 100).toFixed(2)}
                  backDef={''}
                  trending={'up'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
          {selectedCampaign?.incrementalROAS && (
            <>
              <div className="w-full flex flex-col items-center justify-center relative h-full">
                <MetricNode
                  title={selectedCampaign?.mainCircTitle ? selectedCampaign?.mainCircTitle : 'Incremental ROAS'}
                  frontDef={'$'}
                  metricValue={selectedCampaign['incrementalROAS']}
                  backDef={null}
                  trending={'down'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              </div>
              <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
            </>
          )}
        </div>
        <div className="div10 bg-white rounded-xl shadow-md flex pl-2 sm:px-8 items-center justify-center relative">
          {selectedCampaign?.bar ? (
            <>
              <div className="absolute top-3 left-4 text-sm text-gray-500">
                {selectedCampaign.barTitle ? selectedCampaign.barTitle : 'Lift'}
              </div>
              <BarChart
                palette={chartPalette}
                data={selectedCampaign?.bar}
                frontDef={selectedCampaign?.barFrontSymbol ? selectedCampaign?.barFrontSymbol : '$'}
                backDef={selectedCampaign?.barBackSymbol ? selectedCampaign?.barBackSymbol : ''}
              />
            </>
          ) : (
            <div className="flex flex-col items-center">
              <div className="flex flex-row w-full items-center mb-8">
                <div className="flex flex-col w-full items-center">
                  <MetricNode
                    title="Estimated Units Moved"
                    frontDef={''}
                    metricValue={Math.round(selectedCampaign['estimatedUnitsMoved'] / 1000)}
                    backDef={'K'}
                    trending={'down'}
                    size={'flex'}
                    isCampaignRendered={isCampaignRendered}
                  />
                </div>
                <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
                <div className="flex flex-col w-full items-center">
                  <MetricNode
                    title="Sales From New Buyers"
                    frontDef={'$'}
                    metricValue={selectedCampaign['salesFromNewBuyers']}
                    backDef={'K'}
                    trending={'down'}
                    size={'flex'}
                    isCampaignRendered={isCampaignRendered}
                  />
                </div>
              </div>
              <div className="hidden w-full lg:flex md:h-[1px] bg-slate-200 mb-4 mt-2"></div>
              <div className="text-xl text-gray-600 font-light w-full px-16 mt-4">
                {isCampaignRendered ? <>{selectedCampaign['insight03']}</> : <NoData />}
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedCampaign['dataGrid'] && (
        <>
          <div className="hidden lg:flex w-full ml-4 mt-0 mb-1 sm:mb-6 sm:mt-2 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500">
            Tactic Detail
          </div>

          <div className="hidden lg:flex items-center justify-center shadow-md rounded-xl mx-4 mb-4 p-4 bg-white min-h-[320px] ">
            {isCampaignRendered && selectedCampaign['dataGrid'] ? (
              <DataGridDemoMUI data={selectedCampaign} />
            ) : (
              <NoData />
            )}
          </div>
        </>
      )}
      {selectedCampaign?.bar2 && selectedCampaign?.donut2 && selectedCampaign?.donut3 && (
        <>
          <div className="hidden lg:flex w-full ml-4 mt-0 mb-1 sm:mb-6 sm:mt-2 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500">
            Retailer Redemption Breakout
          </div>
          <div className="hidden lg:flex items-center justify-center w-full box px-2 pb-4 min-h-[200px]">
            <div className="flex items-center justify-center flex-col shadow-md rounded-xl h-full bg-white relative w-6/12 mx-2 px-4 pt-2">
              {isCampaignRendered && selectedCampaign['bar2'] ? (
                <>
                  <div className="absolute top-3 left-4 text-sm text-gray-500">{selectedCampaign['bar2']['title']}</div>
                  <BarChart palette={chartPalette} data={selectedCampaign['bar2']['values']} frontDef="$" backDef="" />
                </>
              ) : (
                <NoData />
              )}
            </div>

            <div className="hidden lg:flex items-center justify-center flex-col shadow-md rounded-xl h-full bg-white relative w-3/12 mx-2">
              {isCampaignRendered && selectedCampaign['donut2'] ? (
                <>
                  <div className="absolute top-3 left-4 text-sm text-gray-500">
                    {selectedCampaign['donut2']['title']}
                  </div>
                  <div className="relative flex items-center justify-center mt-2">
                    <div className="absolute">
                      <div className="text-xl font-bold text-black mb-[-6px]">
                        {selectedCampaign['donut2']['centerCallout']['value']}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        {selectedCampaign['donut2']['centerCallout']['label']}
                      </div>
                    </div>
                    <TwoSimplePieChart data={selectedCampaign['donut2']['values']} palette={chartPalette} symbol={''} />
                  </div>
                </>
              ) : (
                <NoData />
              )}
              <div className="hidden w-full lg:flex md:h-[1px] bg-slate-200 mb-4 mt-2"></div>
              {isCampaignRendered && selectedCampaign['donut2'] ? (
                <div className="flex flex-row flex-wrap justify-center w-3/4 mt-1">
                  {selectedCampaign['donut2']['values'].map((item: any, index: any) => {
                    return (
                      <div className="flex items-center min-w-1/8 m-1" key={index}>
                        <div
                          key={index}
                          className="w-[12px] h-[12px] bg-slate-200 m-[2px] rounded-[2px]"
                          style={{
                            background: chartPalette[index],
                          }}
                        ></div>
                        <div className="flex text-[10px] text-gray-500 justify-start ml-[1px] mr-[4px]">
                          {item.label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoData />
              )}
            </div>

            <div className="flex items-center justify-center flex-col shadow-md rounded-xl h-full bg-white relative w-3/12 mx-2">
              {selectedCampaign['donut3'] ? (
                <>
                  <div className="absolute top-3 left-4 text-sm text-gray-500">
                    {selectedCampaign['donut3']['title']}
                  </div>
                  <div className="relative flex items-center justify-center mt-2">
                    <div className="absolute">
                      <div className="text-xl font-bold text-black mb-[-6px]">
                        {selectedCampaign['donut3']['centerCallout']['value']}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        {selectedCampaign['donut3']['centerCallout']['label']}
                      </div>
                    </div>
                    <TwoSimplePieChart
                      data={selectedCampaign['donut3']['values']}
                      palette={chartPalette}
                      symbol={'%'}
                    />
                  </div>
                </>
              ) : (
                <NoData />
              )}
              <div className="hidden w-full lg:flex md:h-[1px] bg-slate-200 mb-4 mt-2"></div>
              {selectedCampaign['donut3'] ? (
                <div className="flex flex-row flex-wrap justify-center w-full mx-2 mt-1">
                  {selectedCampaign['donut3']['values'].map((item: any, index: any) => {
                    return (
                      <div className="flex items-center min-w-1/8 m-1" key={index}>
                        <div
                          key={index}
                          className="w-[12px] h-[12px] bg-slate-200 m-[2px] rounded-[2px]"
                          style={{
                            background: chartPalette[index],
                          }}
                        ></div>
                        <div className="flex text-[10px] text-gray-500 justify-start ml-[1px] mr-[4px]">
                          {item.label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
