import MultiSelectMUI from './MultiSelectMUI';
import { cubicBezier, motion } from 'framer-motion';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';

export const Selectors = (props: any) => {
  const {
    isCampaignRendered,
    selectedCampaign,
    selectedProgramName,
    programNames,
    handleCampaignChange,
    handlePartnerChange,
    selectedPartnerName,
    partnerNames,
    years,
    handleYearChange,
    selectedYear,
    setSelectedYear,
  } = props;

  return (
    <>
      {isCampaignRendered ? (
        <div className={'flex w-full'}>
          <div className="flex flex-col lg:flex-row w-full sm:ml-2 mt-6">
            <div className="flex flex-col lg:flex-row w-full mr-8 filters mb-4 sm:mb-0">
              <div className="flex w-full sm:w-auto justify-center sm:justify-start sm:mr-4">
                <MultiSelectMUI
                  currValue={selectedYear}
                  label="Year"
                  width={220}
                  allValues={years}
                  handleChange={handleYearChange}
                />
              </div>
              <div className="flex w-full sm:w-auto justify-center sm:justify-start sm:mr-4">
                <MultiSelectMUI
                  currValue={selectedPartnerName}
                  label="Partner"
                  allValues={partnerNames.sort()}
                  handleChange={handlePartnerChange}
                />
              </div>
              <div className="flex w-full sm:w-auto justify-center sm:justify-start sm:mr-4">
                <MultiSelectMUI
                  currValue={selectedProgramName}
                  label="Program"
                  width={220}
                  allValues={programNames}
                  handleChange={handleCampaignChange}
                />
              </div>
              {/*<div className="flex w-full sm:w-auto justify-center sm:justify-start mr-4">*/}
              {/*  <MultiSelectMUI*/}
              {/*    currValue={'Partner Reports'}*/}
              {/*    label="Report Groups"*/}
              {/*    width={220}*/}
              {/*    allValues={['Partner Reports']}*/}
              {/*    handleChange={handlePartnerChange}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="flex w-full sm:w-auto justify-center sm:justify-start mr-4">*/}
              {/*  <MultiSelectMUI*/}
              {/*    currValue={'Campaign Summary'}*/}
              {/*    label="Campaign Types"*/}
              {/*    width={220}*/}
              {/*    allValues={['Campaign Summary']}*/}
              {/*    handleChange={handlePartnerChange}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div className="hidden xl:flex w-full sm:w-1/2 flex-col sm:flex-row justify-end filters mr-4 -mb-12 -mt-4">
              {isCampaignRendered && selectedCampaign['downloadableReport'] && (
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                    delay: 0,
                    duration: 0.3,
                  }}
                >
                  <a
                    href={`/pdfs/${selectedCampaign['downloadableReport']}`}
                    target="_blank"
                    className="flex items-center justify-center flex-col"
                  >
                    <img
                      src={`/images/${selectedCampaign['downloadableReportThumb']}`}
                      alt="download report"
                      className="w-[180px] rounded-lg shadow-md"
                    />
                    <div className="text-xs text-gray-500 mt-1 flex-row flex items-center justify-center hover:text-blue-600">
                      <DownloadIcon sx={{ width: '16px', margin: '2px 2px 0 0' }} />
                      Download Full Report
                    </div>
                  </a>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full ml-2 mt-6 min-h[72px]"></div>
      )}
    </>
  );
};
