import MetricNode from './MetricNode';
import React from 'react';
import { chartPalette } from './Themes';
import BarChart from './BarChart';
import NoData from './NoData';
import { CenterCircleNode } from './CenterCircleNode';
import { Button } from '@mui/material';

export const CampaignTemplate03 = (props: any) => {
  const {
    selectedCampaign,
    isCampaignRendered,
    setSelectedPartnerName,
    campaignData,
    setCampaignTemplate,
    setSelectedCampaignName,
    setCampaignNames,
    setProgramNames,
    setSelectedProgramName,
  } = props;

  const handlePartnerClick = (partner: any, year: any, index: number) => {
    console.log(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSelectedPartnerName(partner);
    const campaignNames: any[] = [];
    const programNames: any = [];
    let cid: any = 0;
    if (year === '2022') {
      cid = 1;
    }
    console.log('campaignData: ', campaignData['campaigns']);
    campaignData['campaigns'].forEach((campaign: any) => {
      if (campaign['partner'] === partner) {
        campaignNames.push(campaign['name']);
        programNames.push(campaign['programName']);
        setCampaignTemplate(campaign['template']);
        console.log(campaign['template']);
      }
    });
    setCampaignNames(campaignNames);
    // setSelectedCampaignName(campaignNames[index]);
    setProgramNames(programNames);
    // setSelectedProgramName(programNames[index]);
    console.log('campaignNames: ', campaignNames);
    console.log('programNames: ', programNames);
    console.log('campaignId: ', cid);
  };

  const getBgColor = (value: any) => {
    if (value < 1.5) {
      return '#16A34A';
    }
    if (value >= 1.5 && value < 2.5) {
      return '#FB923C';
    }
    if (value >= 2.5) {
      return '#DC2625';
    }
  };

  const getSales = (sales: any) => {
    if (sales < 1000000 && sales > 0) {
      return `$${sales}K`;
    } else if (sales > 1000000) {
      return `$${sales / 1000000}M`;
    } else if (sales === 0) {
      return '-';
    }
  };

  return (
    <>
      <div className={'grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 sm:px-4 mt-6 mb-6'}>
        <div className={'flex flex-col relative min-h-[160px]'}>
          <div className={'flex flex-col justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2'}>
            {isCampaignRendered && selectedCampaign['totalInvestment'] ? (
              <MetricNode
                title="Program Investment"
                frontDef={'$'}
                metricValue={Math.round(selectedCampaign['totalInvestment'] / 1000)}
                backDef={'K'}
                trending={'up'}
                size={'large'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>

          <div className={'flex flex-col justify-center shadow-md rounded-xl bg-white p-4 h-full mt-2'}>
            {isCampaignRendered && selectedCampaign['totalInvestment'] ? (
              <MetricNode
                title="Program Impressions"
                frontDef={''}
                metricValue={Math.round(selectedCampaign['totalImpressions'] / 1000000)}
                backDef={'M'}
                trending={'up'}
                size={'large'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>

        <div className={'flex flex-col shadow-md rounded-xl bg-white pt-16 pb-8 px-8 relative min-h-[160px]'}>
          {isCampaignRendered && (
            <div className="absolute top-3 left-4 text-sm text-gray-500">Performance Analysis</div>
          )}
          {isCampaignRendered ? (
            <CenterCircleNode
              mainCircVal={selectedCampaign['mainCircVal']}
              mainCircCur={selectedCampaign['mainCircCur']}
              mainCircTitle={'ROAS'}
              rangeIndicators={false}
              frontDef={'$'}
              backDef={''}
            />
          ) : (
            <NoData />
          )}
          {isCampaignRendered ? (
            <div className="text-md text-slate-500 -mb-8 mt-6 px-0 sm:px-12">
              <span className="text-5xl font-serif align-bottom inline-block mb-[-16px]">&ldquo;</span>
              {selectedCampaign['insight01']}
              <span className="text-5xl align-top ml-[2px] font-serif inline-block mt-1">&rdquo;</span>
            </div>
          ) : null}
        </div>
        {/*<div*/}
        {/*  className={*/}
        {/*    'flex flex-row items-center justify-center shadow-md rounded-xl bg-white p-4 relative min-h-[160px]'*/}
        {/*  }*/}
        {/*>*/}
        {/*  {isCampaignRendered && (*/}
        {/*    <div className="absolute top-3 left-4 text-sm text-gray-500">Performance Comparison</div>*/}
        {/*  )}*/}
        {/*  {isCampaignRendered && selectedCampaign['barInvestment'] && (*/}
        {/*    <BarChart*/}
        {/*      palette={chartPalette}*/}
        {/*      data={selectedCampaign['barInvestment']}*/}
        {/*      frontDef="$"*/}
        {/*      backDef=""*/}
        {/*      height={380}*/}
        {/*      horizontal={true}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>

      {selectedCampaign['programCards'] && (
        <div className="flex w-full ml-2 mb-6 sm:mb-6 sm:mt-2 sm:ml-6 text-xl sm:text-2xl font-extralight tracking-widest text-slate-500">
          Program Reports
        </div>
      )}

      <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:px-4 pb-4'}>
        {isCampaignRendered &&
          selectedCampaign['programCards'] &&
          selectedCampaign['programCards'].map((program: any, index: number) => (
            <div
              className={'flex flex-col shadow-md rounded-xl bg-white relative min-h-[160px] overflow-hidden'}
              key={index}
            >
              <div
                className={`h-[200px] w-full bg-cover bg-center bg-slate-200`}
                style={{ backgroundImage: `url(/images/${program['downloadableReportThumb']})` }}
              ></div>

              <div className={'flex w-full items-center flex-row px-8 min-h-[88px]'}>
                <div className={'flex w-1/2'}>
                  <img
                    src={`/images/dash-logos/${program['logo']}`}
                    alt={`logo ${program.retailer}`}
                    className={''}
                    style={{ width: `${program['logoWidth']}px` }}
                  />
                </div>
                <div className={'hidden sm:flex w-1/2 justify-end'}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    size={'medium'}
                    onClick={() => handlePartnerClick(program.retailer, program.year, index)}
                  >
                    View Report
                  </Button>
                </div>
              </div>
              <div className={'px-8 pb-8'}>
                {program['program'] && (
                  <div className={'flex w-full flex-row py-1 border-b-[1px] items-center min-h-[48px]'}>
                    <div className={'flex w-full justify-start text-lg font-bold'}>{program['program']}</div>
                  </div>
                )}
                <div className={'flex w-full flex-row py-1 border-b-[1px] items-center min-h-[48px]'}>
                  <div className={'flex w-1/2 text-gray-500 text-md text-left'}>Media Investment</div>
                  <div className={'flex w-1/2 justify-end text-xl font-bold'}>${program['mediaInvestment']}K</div>
                </div>
                <div className={'flex w-full flex-row py-1 border-b-[1px] items-center min-h-[48px]'}>
                  <div className={'flex w-1/2 text-gray-500 text-md text-left'}>KPI</div>
                  <div className={'flex w-1/2 justify-end text-xl font-bold'}>{program?.kpi}</div>
                </div>
                <div className={'flex w-full flex-row py-1 border-b-[1px] items-center min-h-[48px]'}>
                  <div className={'flex w-1/2 text-gray-500 text-md'}>
                    {program.retailer === 'Ibotta' ? 'Redemptions' : 'Sales'}
                  </div>
                  <div className={'flex w-1/2 justify-end text-xl font-bold'}>
                    {program.retailer !== 'Ibotta'
                      ? getSales(program?.sales)
                      : `${(program?.redemptions / 1000).toFixed(2)}K`}
                  </div>
                </div>
                <div className={'flex w-full flex-row py-1 border-b-[1px] items-center min-h-[48px]'}>
                  <div className={'flex w-1/2 text-gray-500 text-md'}>CPA</div>
                  <div className={`flex w-1/2 justify-end`}>
                    <div
                      className={`px-2 py-1 rounded-3xl text-white text-xl font-bold min-w-[80px]`}
                      style={{ backgroundColor: program.cpa && getBgColor(program['cpa']) }}
                    >
                      {program.cpa ? `$${program?.cpa}` : <span className={'text-black -mr-2'}>-</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
