import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { axisClasses } from '@mui/x-charts';
import { NumericFormat } from 'react-number-format';

const data1 = [
  { label: 'Group A', value: 400 },
  { label: 'Group B', value: 300 },
  { label: 'Group C', value: 300 },
  { label: 'Group D', value: 200 },
  { label: 'Group E', value: 278 },
  { label: 'Group F', value: 189 },
];

export default function TwoSimplePieChart(props: any) {
  const { data, palette, symbol } = props;

  const valueFormatter = (props: any) => {
    if (symbol === '') {
      // return `${Math.round(props.value)}`;
      return props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // return <NumericFormat value={props.value} displayType="text" thousandSeparator={true} />;
    } else {
      return `${Math.round(props.value)}${symbol}`;
    }
  };

  return (
    <PieChart
      colors={palette}
      series={[
        {
          data: data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          // cx: 120,
          // cy: 120,
          innerRadius: 46,
          outerRadius: 84,
          paddingAngle: 0,
          cornerRadius: 0,
          startAngle: 0,
          endAngle: 360,
          //@ts-ignore
          valueFormatter: valueFormatter,
        },
      ]}
      margin={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
      height={200}
      width={200}
      slotProps={{
        legend: {
          hidden: true,
          direction: 'column',
          position: { vertical: 'middle', horizontal: 'right' },
          padding: 10,
          itemMarkWidth: 16,
          itemMarkHeight: 16,
          markGap: 10,
          itemGap: 10,
          labelStyle: {
            fontSize: 10,
            fill: '#666',
          },
        },
      }}
    />
  );
}
