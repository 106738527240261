import React, { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import LoginBox from '../Components/User/LoginBox';
import RegisterBox from '../Components/User/RegisterBox';
import PasswordBox from '../Components/User/PasswordBox';
import { motion, useAnimate, cubicBezier } from 'framer-motion';
import SnackBar from '../Components/SnackBar';
import LogoBarrows from '../assets/logo192.png';
import './LoginPage.css';
import ReturnEnv from '../Components/User/Utilities';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#111',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const LoginPage = (props: any) => {
  const { setDisplayName, userEmail, setUserEmail, userPassword, setUserPassword, setUserClaim } = props;

  const navigate = useNavigate();
  const [userResetEmail, setUserResetEmail] = React.useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [scopeLogBox, animate] = useAnimate();
  const [isLogin, setIsLogin] = React.useState(true);
  const [isRegister, setIsRegister] = React.useState(false);
  const [isPassword, setIsPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loginBoxAnimParams, setLoginBoxAnimParams] = React.useState({
    delay: 2.5,
    y: 1000,
    duration: 0.75,
  });

  const handleLogin = () => {
    // @ts-ignore
    if (ReturnEnv() === 'http://localhost:5000') {
      if (userEmail === '' || userPassword === '' || !validateEmail(userEmail)) {
        shakeIt();
        return;
      } else {
        sendLoginData();
      }
    } else {
      sendLoginData();
    }
  };

  const handleRegister = () => {
    if (userEmail === '' || userPassword === '' || !validateEmail(userEmail)) {
      shakeIt();
      return;
    } else {
      sendRegisterData();
    }
  };

  const handlePassword = () => {
    if (userResetEmail === '' || !validateEmail(userResetEmail)) {
      shakeIt();
      return;
    } else {
      sendPasswordData();
    }
  };

  const sendLoginData = () => {
    setIsLoading(true);
    fetch(`${ReturnEnv()}/login`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail, userPassword }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['Login Status'] === 'Success') {
          console.log('Login Success');
          setUserClaim(data.Claim);
          actuallyLogin();
          setIsLoading(false);
        } else {
          console.log('Login Failed');
          shakeIt();
        }
        setDisplayName(userEmail.split('@')[0]);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const sendRegisterData = () => {
    fetch(`${ReturnEnv()}/register`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail, userPassword }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['Registration Status'] === 'Success') {
          console.log('Registration Success');
          setIsRegister(false);
          setIsLogin(true);
          setSnackBarMessage("We've sent you an email with a link to activate your account.");
          setIsSnackBarOpen(true);
          shakeIt();
        } else if (data['Registration Status'] === 'Invalid Domain') {
          setSnackBarMessage('Registrations are only allowed with a valid Barrows email address.');
          setIsSnackBarOpen(true);
          shakeIt();
        } else {
          console.log('Registration Failed');
          shakeIt();
        }
        setDisplayName(data.username);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const sendPasswordData = () => {
    fetch(`${ReturnEnv()}/send-password`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userResetEmail }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['Retrieve Email'] === 'Success') {
          setSnackBarMessage('We just sent your password to your email');
          setIsSnackBarOpen(true);
        } else {
          setSnackBarMessage("We cound't find your email in our database");
          setIsSnackBarOpen(true);
          shakeIt();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const shakeIt = () => {
    setLoginBoxAnimParams({ delay: 0, y: 100, duration: 0.25 });
    animate('.login-box', { x: [20, -20, 0] }, { duration: 0.3, ease: 'easeInOut', delay: 0 });
  };

  const actuallyLogin = () => {
    animate('.login-box', { y: -1000, opacity: 0 }, { duration: 1, ease: cubicBezier(0.5, -0.4, 0.8, 2) });
    animate(
      '.left-side',
      { x: -1000, opacity: 0 },
      { duration: 0.8, ease: cubicBezier(0.5, -0.4, 0.8, 2), delay: 0.2 }
    );
    animate('.fade-out', { opacity: 0 }, { duration: 0.3, ease: cubicBezier(0.5, -0.4, 0.8, 2), delay: 0 });
    animate(
      '.right-side',
      { x: 1000, opacity: 0, rotate: 60 },
      { duration: 0.8, ease: cubicBezier(0.5, -0.4, 0.8, 2), delay: 0.7 }
    );

    setTimeout(() => {
      navigate('/dashboard');
    }, 2000);
  };

  function validateEmail(userEmail: string) {
    const regex = /^[a-zA-Z0–9._-]+@[a-zA-Z0–9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(userEmail);
  }

  useEffect(() => {
    // @ts-ignore
    if (ReturnEnv() === 'http://localhost:5000') {
      sendLoginData();
    }
  }, []);

  return (
    <>
      <div
        className="flex w-full h-full flex-row items-center justify-center overflow-hidden relative"
        ref={scopeLogBox}
      >
        <motion.img
          src={LogoBarrows}
          alt="Barrows"
          className="z-50 absolute top-10 left-10 w-[32px] sm:hidden"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            ease: 'easeInOut',
            delay: 1.2,
            duration: 0.5,
          }}
        />
        <motion.div
          initial={{ x: -800, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            delay: 0.1,
            duration: 1,
          }}
          className="flex flex-col h-full w-full items-center justify-center hidden xs:hidden sm:hidden md:flex"
        >
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: 'easeInOut',
              delay: 1.5,
              duration: 1,
            }}
            className="z-30"
          >
            <div className="text-5xl font-bold text-white tracking-wide">StudioB</div>
          </motion.div>
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: 'easeInOut',
              delay: 2,
              duration: 1,
            }}
            className="flex flex-col items-center z-20"
          >
            <div className="text-sm tracking-[3px] text-white uppercase overflow-hidden">By Barrows</div>
          </motion.div>
        </motion.div>
        <motion.div
          initial={{ rotateY: 90, rotateX: 0, opacity: 1 }}
          animate={{ rotateY: 0, rotateX: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeInOut', delay: 0.5, duration: 0.8 }}
          className="flex h-full w-full flex-col items-center justify-center z-10 bg-blue-50 right-side"
        >
          {isLogin && (
            <LoginBox
              handleLogin={handleLogin}
              theme={theme}
              setIsLogin={setIsLogin}
              setIsRegister={setIsRegister}
              setIsPassword={setIsPassword}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              setDisplayName={setDisplayName}
              loginBoxAnimParams={loginBoxAnimParams}
              isLoading={isLoading}
            />
          )}
          {isRegister && (
            <RegisterBox
              handleRegister={handleRegister}
              theme={theme}
              setIsLogin={setIsLogin}
              setIsRegister={setIsRegister}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              resetAnim={setLoginBoxAnimParams}
            />
          )}
          {isPassword && (
            <PasswordBox
              handlePassword={handlePassword}
              theme={theme}
              setIsLogin={setIsLogin}
              setIsPassword={setIsPassword}
              setUserResetEmail={setUserResetEmail}
              userResetEmail={userResetEmail}
              resetAnim={setLoginBoxAnimParams}
            />
          )}
        </motion.div>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 200 }}
          transition={{
            ease: 'easeInOut',
            delay: 0.8,
            duration: 1,
          }}
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '300px',
            left: '300px',
            backgroundColor: '#111',
            zIndex: -1,
          }}
          className="left-side"
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 2, rotate: 30 }}
          animate={{ opacity: 0.33, scale: 1, rotate: 0 }}
          transition={{
            ease: cubicBezier(0.8, 0.4, 0.55, 1),
            delay: 2,
            duration: 2,
          }}
          className="login-bg w-[50%] h-[40px] z-50 fade-out sm:hidden md:block"
        ></motion.div>
        {isSnackBarOpen && (
          <SnackBar
            setIsSnackBarOpen={setIsSnackBarOpen}
            isSnackBarOpen={isSnackBarOpen}
            snackBarMessage={snackBarMessage}
          />
        )}
      </div>
    </>
  );
};

export default LoginPage;
