import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { motion, cubicBezier } from 'framer-motion';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import ReturnEnv from './Utilities';

const LoginBox = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleLogin,
    theme,
    setIsRegister,
    setIsLogin,
    loginBoxAnimParams,
    userEmail,
    setUserEmail,
    userPassword,
    setUserPassword,
    setIsPassword,
    isLoading,
  } = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const InputPropsEmail = {
    sx: { borderRadius: 1.6 },
  };

  const InputPropsPass = {
    sx: { borderRadius: 1.6 },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <div className="min-w-[320px] sm:min-w-[320px] lg:min-w-[480px]">
      <motion.div
        initial={{ y: loginBoxAnimParams.y, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
          delay: loginBoxAnimParams.delay,
          duration: loginBoxAnimParams.duration,
        }}
        className="min-w-[60%] login-box shake-it"
      >
        <ThemeProvider theme={theme}>
          <Box
            component="form"
            noValidate
            autoComplete="on"
            sx={{ minWidth: '60%' }}
            className="shadow-lg pt-16 pb-10 px-8 bg-white rounded-lg"
          >
            <h2 className="text-3xl font-bold mb-4 text-black">Welcome to StudioB!</h2>
            <div className="p-2">
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                InputProps={InputPropsEmail}
                className="w-full"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="p-2">
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                autoComplete={showPassword ? 'on' : 'off'}
                variant="outlined"
                size="small"
                InputProps={InputPropsPass}
                className="w-full"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleLogin();
                  }
                }}
              />
            </div>
            <motion.div className="p-2">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                sx={{ borderRadius: 1.6 }}
                className="w-full"
                onClick={handleLogin}
              >
                Login
                <div className={'absolute right-2 top-2'}>
                  {isLoading && <CircularProgress size={20} color="inherit" />}
                </div>
              </Button>
              <div className="flex flex-row justify-end mt-2 mr-1 text-sm">
                <div
                  className="underline cursor-pointer mr-2"
                  onClick={() => {
                    setIsRegister(true);
                    setIsLogin(false);
                  }}
                >
                  Register
                </div>
                <div
                  className="underline cursor-pointer"
                  onClick={() => {
                    setIsPassword(true);
                    setIsLogin(false);
                  }}
                >
                  Forgot password?
                </div>
              </div>
            </motion.div>
          </Box>
        </ThemeProvider>
      </motion.div>
    </div>
  );
};

export default LoginBox;
