import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick(event: any) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function Crumbs(props: any) {
  const { tenantName } = props;
  const breadcrumbs = [
    <Link underline="always" key="1" color="inherit" href="/" onClick={handleClick}>
      NA
    </Link>,
    <Link
      underline="always"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      {tenantName}
    </Link>,
  ];

  return (
    <div>
      <Breadcrumbs separator=">" aria-label="breadcrumb" sx={{ fontSize: '12px' }}>
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
}
