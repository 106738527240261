import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const action = (
  <Button color="secondary" size="small">
    lorem ipsum dolorem
  </Button>
);

export default function SnackBar(props) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setIsSnackBarOpen(false);
  };

  return (
    <Snackbar
      open={props.isSnackBarOpen}
      // autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ bottom: "100px" }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{ width: "100%", background: "#333" }}
      >
        {props.snackBarMessage}
      </Alert>
    </Snackbar>
  );
}
