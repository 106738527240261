const ReturnEnv = () => {
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("http://127.0.0.1")
  ) {
    return "http://127.0.0.1:5000";
  } else {
    return "https://api.studiob.ai";
  }
};

export default ReturnEnv;
