import MetricNode from './MetricNode';
import React from 'react';
import { chartPalette } from './Themes';
import BarChart from './BarChart';
import NoData from './NoData';
import { CenterCircleNode } from './CenterCircleNode';

export const CampaignTemplate04 = (props: any) => {
  const { selectedCampaign, isCampaignRendered } = props;

  return (
    <>
      <div className={'grid grid-cols-3 gap-4 px-4 mt-6 mb-6'}>
        <div className={'flex flex-col relative min-h-[160px]'}>
          <div className={'flex flex-col justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2'}>
            {isCampaignRendered && selectedCampaign['investment'] ? (
              <MetricNode
                title={Object.keys(selectedCampaign['investment'][0])}
                frontDef={'$'}
                //@ts-ignore
                metricValue={Math.round(Object.values(selectedCampaign['investment'][0]) / 1000)}
                backDef={'K'}
                trending={'up'}
                size={'large'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className={'flex flex-col justify-center shadow-md rounded-xl bg-white p-4 h-full mt-2'}>
            {isCampaignRendered && selectedCampaign['investment'] ? (
              <MetricNode
                title={Object.keys(selectedCampaign['investment'][1])}
                frontDef={''}
                //@ts-ignore
                metricValue={Math.round(Object.values(selectedCampaign['investment'][1]) / 1000000)}
                backDef={'M'}
                trending={'up'}
                size={'large'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>

        <div className={'flex flex-col justify-center shadow-md rounded-xl bg-white relative min-h-[460px]'}>
          {isCampaignRendered && (
            <div className="absolute top-3 left-4 text-sm text-gray-500">Performance Analysis</div>
          )}
          {isCampaignRendered ? (
            <CenterCircleNode
              mainCircVal={selectedCampaign['mainCircVal']}
              mainCircCur={selectedCampaign['mainCircCur']}
              mainCircTitle={'Cost Per Action'}
              rangeIndicators={false}
              frontDef={'$'}
              backDef={''}
            />
          ) : (
            <NoData />
          )}
        </div>

        <div className={'flex flex-col relative'}>
          <div className={'flex w-full h-full'}>
            <div className={'flex flex-col w-1/2 justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2 mr-4'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.keys(selectedCampaign['performance'][0])}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign['performance'][0])}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
            <div className={'flex flex-col w-1/2 justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.keys(selectedCampaign?.performance[1])}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign?.performance[1])}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
          <div className={'flex w-full mt-4 h-full'}>
            <div className={'flex flex-col w-1/2 justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2 mr-4'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.keys(selectedCampaign?.performance[2])}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign?.performance[2])}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
            <div className={'flex flex-col w-1/2 justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.keys(selectedCampaign?.performance[3])}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign?.performance[3])}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
          <div className={'flex w-full mt-4 h-full'}>
            <div className={'flex flex-col w-full justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2 mr-4'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.keys(selectedCampaign?.performance[4])}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign?.performance[4])}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
            <div className={'flex flex-col w-full justify-center shadow-md rounded-xl bg-white p-4 h-full mb-2'}>
              {isCampaignRendered && selectedCampaign['performance'] ? (
                <MetricNode
                  title={Object.values(selectedCampaign?.performance?.[5] || '')}
                  frontDef={''}
                  //@ts-ignore
                  metricValue={Object.values(selectedCampaign?.performance?.[5] || '')}
                  backDef={''}
                  trending={'up'}
                  size={'medium'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
