import { useEffect, useState, useRef } from 'react';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { barrowsTheme, reckittTheme, zespriTheme } from '../Themes';
import ReturnEnv from '../../Components/User/Utilities';

const WorkSpaces = (props: any) => {
  const {
    isOpen,
    isRegionOpen,
    isBrandGroupOpen,
    selectedItem,
    handleSelected,
    handleIsRegionOpen,
    isExpanded,
    isActive,
    theme,
    setTheme,
    userEmail,
    setTenantName,
    setRawAccessData,
    rawAccessData,
    setTenantNames,
    tenantNames,
    tenantName,
    hasTenantChanged,
    setTenantLogo,
    setTid,
    tId,
    selectedCampaign,
    setIsCampaignRendered,
  } = props;
  // figure out tenant id change HERE
  const tree = {} as any;
  const [accessTree, setAccessTree] = useState({} as any);
  const [tempTheme, setTempTheme] = useState(String);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    fetch(`${ReturnEnv()}/get-access`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        setDataLoaded(true);
        setRawAccessData(data);
        if (rawAccessData) {
          buildTenants(data);
        }
        // setAccessTree(buildTree(data, tid));
      });
  }, []);

  useEffect(() => {
    // setTenantName(tenantNames[0]);
  }, [tenantNames, setTenantNames, setTenantName]);

  useEffect(() => {
    let td = '' as any;
    // let td = tId;
    // let td = tenantNames.indexOf(String(tenantName)) + 1;
    console.log('tenantName: ', tenantName);

    try {
      Object.values(rawAccessData['tenants']).forEach((value: any) => {
        if (value['name'] === tenantName) {
          td = value['id'];
          console.log('tenantId: ', value['id']);
        }
      });
    } catch (error) {
      console.log('tenantName not available');
    }

    if (tenantName !== undefined) {
      setAccessTree(buildTree(rawAccessData, String(td)));
    }

    try {
      setTenantLogo(rawAccessData['tenants'][td - 1]['logo']);
    } catch (error) {
      console.log('tenant logo not available');
    }
  }, [hasTenantChanged, selectedCampaign, dataLoaded, rawAccessData]);

  useEffect(() => {
    // console.log("tenantName: ", tenantName);
    if (String(tenantName) === 'Barrows') {
      console.log('Setting theme with tenantName: ', tenantName);
      setTheme(barrowsTheme);
    } else if (String(tenantName) === 'Reckitt') {
      console.log('Setting theme with tenantName: ', tenantName);
      setTheme(reckittTheme);
    } else if (String(tenantName) === 'Zespri') {
      console.log('Setting theme with tenantName: ', tenantName);
      setTheme(zespriTheme);
    } else {
      // setTheme(barrowsTheme);
    }
  }, [tempTheme, setTheme, reckittTheme, barrowsTheme, zespriTheme, tenantName]);

  const buildTenants = (data: any) => {
    let tenantIds: any[] = [];
    let tenantId = '';
    Object.values(data['users']).forEach((value: any) => {
      if (value['email'] === userEmail) {
        tenantId = value['tenantIdList'][0];
        tenantIds = value['tenantIdList'];

        console.log('tenantIds: ', tenantIds);
        console.log('tenantId: ', tenantId);

        setTid(tenantId);
        let tempTenants: any[] = [];
        setTempTheme(data['tenants'][Number(tenantId) - 1]['themeName']);
        // setTenantId(value["tenantIdList"][0]);
        setTenantLogo(data['tenants'][Number(tenantId) - 1]['logo']);

        value['tenantIdList'].forEach((tenantId: any) => {
          console.log(data['tenants'][tenantId - 1]['name']);
          tempTenants.push(data['tenants'][tenantId - 1]['name']);
          // setTenantName(data["tenants"][tenantId - 1]["name"]);
          // console.log("tenantName: ", data["tenants"][tenantId - 1]["name"]);
          // setAccessTree(buildTree(data));
        });
        setTenantNames(tempTenants);
        setTenantName(tempTenants[0]);
      }
    });
    setAccessTree(buildTree(data, tenantId));
  };

  const buildTree = (data: any, ttt: any) => {
    console.log('Building Tree with tenantId: ', ttt);
    // setTid(ttt);
    console.log('selectedCampaign: ', selectedCampaign.tenantIds);

    if (Object.keys(selectedCampaign).length === 0) {
      console.log('selectedCampaign is undefined');
    } else {
      if (selectedCampaign['tenantIds'].includes(String(ttt))) {
        console.log('tenant id is in the campaign');
        setIsCampaignRendered(true);
      } else {
        console.log('tenant id is NOT in the campaign');
        setIsCampaignRendered(false);
      }
    }

    // loop over the whole object
    Object.keys(data).forEach((key, index) => {
      // if key is region
      if (key === 'regions') {
        data[key].forEach((regionKey: any) => {
          // if tenant id is in the region
          if (regionKey['tenantIds'].includes(ttt)) {
            tree[regionKey.name] = {
              brandGroupings: {},
            };
            // loop over the brand groupings
            regionKey['brandGroupingIds'].forEach((brandGroupingId: any) => {
              // find and define brand group
              let brandGroup = data['brandGroupings'].find((group: any) => group.id === brandGroupingId);
              // define brand group in the tree
              tree[regionKey.name]['brandGroupings'][brandGroup.name] = [];
              // loop over the brandIds within region
              regionKey['brandIds'].forEach((brandId: any) => {
                // find and define brandIds
                let brand = data['brands'].find((brand: any) => brand.id === brandId);
                // loop over the brand groupings
                data['brandGroupings'].forEach((group: any, index: any) => {
                  // is current brand id in the brandGrouping > brandIds?
                  if (group['brandIds'].includes(brandId) && brandGroup.name === group.name) {
                    // is brand id already in the tree?
                    tree[regionKey.name]['brandGroupings'][brandGroup.name].push(brand.name);
                  }
                });
              });
            });
          }
        });
      }
    });
    console.log('tree: ', tree);
    return tree;
  };

  return (
    <>
      <div
        className={`w-full flex flex-row py-4 transition-all cursor-pointer hover:bg-blue-50
          ${isOpen ? 'justify-start px-8' : 'justify-center px-4'}
          ${selectedItem === 'workspaces' && `bg-blue-50 border-r-4`}`}
        onClick={() => handleSelected('workspaces')}
        style={{ borderColor: theme.palette.primary.light }}
      >
        <WorkspacesIcon />
        {isOpen && <div className="mx-2 text-m">Workspaces</div>}
        {isOpen && (
          <div className="flex w-full ml-4 items-end">{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        )}
      </div>
      {isOpen && selectedItem === 'workspaces' && isExpanded && (
        <div className="border-b-[1px] border-b-slate-200 pb-2">
          {isRegionOpen &&
            Object.keys(accessTree).map((region: any, index: any) => (
              <div className="text-m my-2 overflow-hidden region" key={index}>
                <div className={isActive ? 'short' : 'tall'} onClick={handleIsRegionOpen}>
                  <div className="bg-slate-100 rounded-2xl mx-4 mt-2 flex items-start px-3 py-1">{region}</div>
                </div>
                {isBrandGroupOpen &&
                  Object.keys(accessTree[region]['brandGroupings']).map((brandGrouping: any, index: any) => (
                    <div className="text-sm" key={index}>
                      <div className="rounded-2xl ml-6 mr-2 flex items-start px-2 py-1 my-1">{brandGrouping}</div>
                      {accessTree[region]['brandGroupings'][brandGrouping].map((brand: any, index: any) => (
                        <div className="text-xs text-slate-600" key={index}>
                          <div className="mx-8 flex items-start px-2">{brand}</div>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default WorkSpaces;
