import LogoBarrows from "../assets/logo-studiob.png";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function Header(props: any) {
  const { displayName, tenantLogo } = props;

  const navigate = useNavigate();

  return (
    <header>
      <div
        className={
          "py-4 px-4 sm:py-4 sm:px-8 flex items-center bg-[#fcfcfc] h-[64px]"
        }
      >
        <div className={"flex container items-center"}>
          <img
            src={LogoBarrows}
            alt="Barrows"
            className="w-[100px] cursor-pointer"
            onClick={() => {
              setTimeout(() => {
                navigate("/login");
              }, 0);
            }}
          />
          <div className="border-r-2 border-r-slate-200 mx-4 h-[32px]"></div>
          <img
            src={`${tenantLogo}`}
            alt=""
            className="max-h-[32px] max-w-[54px]"
          />
        </div>
        <div className={"flex container items-center justify-end"}>
          <div className="shadow-sm shadow-gray-300 rounded-3xl px-4 py-1">
            {displayName.charAt(0).toUpperCase() +
              displayName.split("." || "@")[0].slice(1)}
          </div>
        </div>
      </div>
    </header>
  );
}
