import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { motion, cubicBezier } from "framer-motion";

const PasswordBox = (props: any) => {
  const {
    handlePassword,
    theme,
    setIsPassword,
    setIsLogin,
    userResetEmail,
    setUserResetEmail,
    resetAnim,
  } = props;

  const InputPropsEmail = {
    sx: { borderRadius: 1.6 },
  };

  return (
    <div className="min-w-[320px] sm:min-w-[320px] lg:min-w-[480px]">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
          delay: 0,
          duration: 0.3,
        }}
        className="min-w-[60%] login-box login-box"
      >
        <ThemeProvider theme={theme}>
          <Box
            component="form"
            noValidate
            autoComplete="on"
            sx={{ minWidth: "60%" }}
            className="shadow-lg pt-16 pb-10 px-8 bg-white rounded-lg"
          >
            <h2 className="text-3xl font-bold mb-4 text-black">
              Retrieve Password
            </h2>
            <div className="p-2">
              <TextField sx={{ display: "none" }} />
            </div>
            <div className="p-2">
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                InputProps={InputPropsEmail}
                className="w-full"
                value={userResetEmail}
                onChange={(e) => setUserResetEmail(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handlePassword();
                  }
                }}
              />
            </div>
            <motion.div className="p-2">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                sx={{ borderRadius: 1.6 }}
                className="w-full"
                onClick={handlePassword}
              >
                Email password
              </Button>
              <div className="flex mt-2 flex-row justify-end mr-1 text-sm">
                <span
                  className="underline cursor-pointer ml-2"
                  onClick={() => {
                    resetAnim({ delay: 0, y: 100, duration: 0.25 });
                    setIsPassword(false);
                    setIsLogin(true);
                  }}
                >
                  Back to login
                </span>
              </div>
            </motion.div>
          </Box>
        </ThemeProvider>
      </motion.div>
    </div>
  );
};

export default PasswordBox;
