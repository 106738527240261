import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '12px',
      marginTop: '8px',
    },
  },
};

function getStyles(name: any, personName: any, theme: any) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props: any) {
  const theme = useTheme();
  const { currValue, label, width, allValues, handleChange, size, disabled } = props;

  return (
    <div className={'w-full mr-4 sm:mr-2 max-w-[264px] sm:max-w-full'}>
      <FormControl sx={{ m: 1, width: '100%', minWidth: '164px' }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          size={size}
          // multiple
          value={currValue}
          onChange={handleChange}
          MenuProps={MenuProps}
          label={label}
          disabled={disabled}
          sx={{
            textAlign: 'left',
            '& fieldset': {
              borderRadius: '12px',
            },
            '& .MuiSvgIcon-root': {
              transform: 'scale(1.5)',
              color: '#333',
              marginRight: '4px',
            },
          }}
        >
          {allValues.map((value: any) => (
            <MenuItem key={value} value={value} style={getStyles(value, currValue, theme)}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
