import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LogoBarrows from "../assets/logo192.png";
import ReturnEnv from "../Components/User/Utilities";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#111",
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const ActivatePage = (props: any) => {
  const navigate = useNavigate();
  const [title, setTitle] = React.useState("");
  const [paragraph, setParagraph] = React.useState("");
  const [isActivated, setIsActivated] = React.useState(false);

  const backToLogin = () => {
    navigate("/login");
  };

  const urlParams = new URLSearchParams(window.location.search);
  const activationToken = urlParams.get("token");

  React.useEffect(() => {
    if (activationToken) {
      fetch(`${ReturnEnv()}/activate`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ activationToken: activationToken }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["Activation Status"] === "Success") {
            console.log("Login Success");
            setTitle("You did it!");
            setParagraph("Congrats, your account has just been activated.");
            setIsActivated(true);
          } else {
            console.log("Activation Failed");
            setTitle("Something Went Wrong");
            setParagraph("Your activation token seems to be invalid.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [activationToken]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <img
        src={LogoBarrows}
        alt="Barrows"
        className="z-50 absolute top-10 left-10 w-[32px]"
      />
      <ThemeProvider theme={theme}>
        <div className="text-6xl m-4">{title}</div>
        <div className="text-m m-4">{paragraph}</div>
        <div className="m-4">
          {isActivated && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              sx={{ borderRadius: 1.6 }}
              className="w-40"
              onClick={backToLogin}
            >
              Login
            </Button>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ActivatePage;
