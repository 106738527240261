import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

export default function BarsDataset(props: any) {
  const { palette, data, title, frontDef, backDef, height, horizontal } = props;
  const valueFormatter = (value: number | null) => `${frontDef}${value}${backDef}`;

  const chartSetting = {
    height: height ? height : 300,
    margin: { top: 48, right: horizontal ? 32 : 48, bottom: 32, left: horizontal ? 124 : 48 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  };

  return (
    <BarChart
      layout={horizontal ? 'horizontal' : 'vertical'}
      colors={palette}
      grid={{ horizontal: true }}
      dataset={data}
      // xAxis={[{ scaleType: 'band', dataKey: 'b' }]}
      xAxis={horizontal ? [] : [{ scaleType: 'band', dataKey: 'b' }]}
      yAxis={horizontal && [{ scaleType: 'band', dataKey: 'b' }]}
      series={[{ dataKey: 'a', label: title ? title : 'Value', valueFormatter }]}
      slotProps={{
        legend: {
          hidden: true,
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          padding: 0,
        },
      }}
      {...chartSetting}
      sx={{
        '& .MuiBarElement-root:nth-of-type(1)': {
          fill: palette[0],
        },
        '& .MuiBarElement-root:nth-of-type(2)': {
          fill: palette[1],
        },
        '& .MuiBarElement-root:nth-of-type(3)': {
          fill: palette[2],
        },
        '& .MuiBarElement-root:nth-of-type(4)': {
          fill: palette[3],
        },
        '& .MuiBarElement-root:nth-of-type(5)': {
          fill: palette[4],
        },
        '& .MuiBarElement-root:nth-of-type(6)': {
          fill: palette[5],
        },
        '& .MuiChartsAxis-bottom, .MuiChartsAxis-tickContainer, .MuiChartsAxis-left, .MuiChartsAxis-tickContainer, .MuiChartsAxis-line, .MuiChartsAxis-label':
          {
            opacity: 0.7,
          },
      }}
    />
  );
}
