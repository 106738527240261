import React from 'react';
import AnimatedNumbers from 'react-animated-numbers';
import TinyLineChart from './TinyLineChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { TrendingDown } from '@mui/icons-material';
import { motion, AnimatePresence, cubicBezier } from 'framer-motion';
import NoData from './NoData';

const MetricNode = (props: any) => {
  const { title, frontDef, metricValue, backDef, trending, size, isCampaignRendered } = props;
  const animatedNumbers = false;
  const pData: any[] = [];

  for (let i = 0; i < 7; i++) {
    pData.push(Math.floor(Math.random() * 10));
  }

  const getClasses = () => {
    const standardClasses = 'flex flex-row ';

    if (size === 'small') {
      return 'font-black text-[2em] lg:text-[1.6em] ' + standardClasses;
    } else if (size === 'medium') {
      return (
        'font-black text-[3em] sm:text-[4em] md:text-[3em] lg:text-[1.6em] xl:text-[2em] 2xl:text-[2em] ' +
        standardClasses
      );
    } else if (size === 'flex') {
      return (
        'font-black text-[1em] sm:text-[4em] md:text-[3em] lg:text-[1.6em] xl:text-[2em] 2xl:text-[2em] ' +
        standardClasses
      );
    } else if (size === 'large') {
      return 'font-black text-6xl ' + standardClasses;
    } else {
      return 'blah ' + standardClasses;
    }
  };

  const randomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  return (
    <>
      {isCampaignRendered ? (
        <div className="flex flex-col w-full items-center justify-center">
          <div
            // className={`${size === "small" ? "text-xl" : "text-[3em] sm:text-[4em] md:text-[3em] lg:text-[2em] xl:text-[2em] 2xl:text-[2em]"} font-black flex flex-row`}
            className={getClasses()}
          >
            <span className="align-super">{metricValue ? frontDef : null}</span>
            {animatedNumbers && (
              <AnimatedNumbers
                transitions={(index) => ({
                  type: 'spring',
                  duration: 0.3,
                })}
                animateToNumber={metricValue}
              />
            )}
            <AnimatePresence>
              <motion.div
                initial={{ x: randomNumber(20, 40), opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  ease: cubicBezier(0.21, 1.21, 0.42, 1.16),
                  delay: 0.1,
                  duration: 0.5,
                }}
              >
                <div>
                  {metricValue ? metricValue : <div className="text-xs text-gray-300 font-normal">No data</div>}
                </div>
              </motion.div>
            </AnimatePresence>
            <span>{metricValue ? backDef : null}</span>
          </div>
          <div className="text-xs text-gray-500 w-2/3 flex items-center justify-center">
            <div>{metricValue ? title : null}</div>
          </div>
          <div className="w-full flex justify-center mt-4 absolute bottom-0">
            {/*<TinyLineChart*/}
            {/*  data={{*/}
            {/*    pData,*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
          {trending === 'up' ? (
            <div className="trending absolute top-1 right-2">
              {/*<TrendingUpIcon sx={{ color: '#16A34A', width: '18px' }} />*/}
            </div>
          ) : trending === 'down' ? (
            <div className="trending absolute top-1 right-2">
              {/*<TrendingDown sx={{ color: '#9433EA', width: '18px' }} />*/}
            </div>
          ) : null}
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default MetricNode;
