import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { motion, cubicBezier } from "framer-motion";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const RegisterBox = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleRegister,
    theme,
    setIsRegister,
    setIsLogin,
    userEmail,
    setUserEmail,
    userPassword,
    setUserPassword,
    resetAnim,
  } = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const InputPropsEmail = {
    sx: { borderRadius: 1.6 },
  };

  const InputPropsPass = {
    sx: { borderRadius: 1.6 },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <div className="min-w-[320px] sm:min-w-[320px] lg:min-w-[480px]">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
          delay: 0,
          duration: 0.3,
        }}
        className="min-w-[60%] login-box"
      >
        <ThemeProvider theme={theme}>
          <Box
            component="form"
            noValidate
            autoComplete="on"
            sx={{ minWidth: "60%" }}
            className="shadow-lg pt-16 pb-10 px-8 bg-white rounded-lg login-box"
          >
            <h2 className="text-3xl font-bold mb-4 text-black">
              Register to StudioB!
            </h2>
            <div className="p-2">
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                InputProps={InputPropsEmail}
                className="w-full"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="p-2">
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                autoComplete={showPassword ? "on" : "off"}
                variant="outlined"
                size="small"
                InputProps={InputPropsPass}
                className="w-full"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleRegister();
                  }
                }}
              />
            </div>
            <motion.div className="p-2">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                sx={{ borderRadius: 1.6 }}
                className="w-full"
                onClick={handleRegister}
              >
                Register
              </Button>
              <div className="flex mt-2 flex-row justify-end mr-1 text-sm">
                Already registered?{" "}
                <span
                  className="underline cursor-pointer ml-2"
                  onClick={() => {
                    resetAnim({ delay: 0, y: 100, duration: 0.25 });
                    setIsRegister(false);
                    setIsLogin(true);
                  }}
                >
                  Login now
                </span>
              </div>
            </motion.div>
          </Box>
        </ThemeProvider>
      </motion.div>
    </div>
  );
};

export default RegisterBox;
