import CircularProgress from '@mui/material/CircularProgress';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import AnimatedNumbers from 'react-animated-numbers';
import React from 'react';

export const CenterCircleNode = (props: any) => {
  const {
    topRightCircVal,
    topRightCircCur,
    mainCircVal,
    mainCircCur,
    bottomLeftCircVal,
    bottomCircleCur,
    rangeIndicators,
    frontDef,
    backDef,
    mainCircTitle,
  } = props;

  const getColor = (value: number) => {
    if (value > 0.99) {
      return '#16A34A';
    } else {
      return '#DC2625';
    }
  };

  return (
    <div className="w-full">
      {rangeIndicators && (
        <div className="w-full flex items-end flex-col -mt-2 sm:mt-0 mb-0 sm:-mb-5 relative h-[80px]">
          <div className="absolute">
            <CircularProgress
              variant="determinate"
              value={100}
              size={80}
              color="primary"
              thickness={5}
              sx={{
                color: '#f7f7f7',
              }}
            />
          </div>
          <div className="flex items-center justify-center">
            <CircularProgress
              variant="determinate"
              value={topRightCircVal}
              size={80}
              color="primary"
              thickness={5}
              sx={{
                color: '#16A34A',
                strokeLinecap: 'round',
              }}
            />
            <div
              className={`absolute text-sm font-black text-[${getColor(topRightCircCur)}] w-full h-full flex items-center justify-center`}
            >
              {frontDef}
              {topRightCircCur}
              {backDef}
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center">
        <div className="absolute">
          <AnimatePresence>
            <motion.div
              initial={{
                y: 80,
                opacity: 1,
                transform: 'rotate(-30deg)',
              }}
              animate={{
                y: 0,
                opacity: 1,
                transform: 'rotate(0deg)',
              }}
              transition={{
                ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                delay: 0.1,
                duration: 0.5,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={100}
                size={260}
                color="primary"
                thickness={5}
                sx={{
                  color: '#f7f7f7',
                }}
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <div>
          <AnimatePresence>
            <motion.div
              initial={{
                y: -80,
                opacity: 1,
                transform: 'rotate(-45deg)',
              }}
              animate={{
                y: 0,
                opacity: 1,
                transform: 'rotate(0deg)',
              }}
              transition={{
                ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                delay: 0.1,
                duration: 0.8,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={mainCircVal}
                size={260}
                color="primary"
                thickness={5}
                sx={{
                  color: getColor(mainCircCur),
                  strokeLinecap: 'round',
                }}
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="absolute">
          <div className={`text-5xl text-[${getColor(mainCircCur)}] font-black flex flex-row`}>
            {frontDef}
            <AnimatedNumbers
              transitions={(index) => ({
                type: 'spring',
                duration: index + 0.3,
              })}
              animateToNumber={mainCircCur}
            />
            {backDef}
          </div>
          <div className="text-sm mt-1 text-gray-500">{mainCircTitle}</div>
        </div>
      </div>
      {rangeIndicators && (
        <div className="w-full flex items-start flex-col -mt-2 sm:-mt-6 relative h-[80px]">
          <div className="absolute">
            <CircularProgress
              variant="determinate"
              value={100}
              size={80}
              color="primary"
              thickness={5}
              sx={{
                color: '#f7f7f7',
              }}
            />
          </div>
          <div className="flex items-center justify-center">
            <CircularProgress
              variant="determinate"
              value={bottomLeftCircVal}
              size={80}
              color="primary"
              thickness={5}
              sx={{
                color: '#DC2625',
                strokeLinecap: 'round',
              }}
            />
            <div
              className={`absolute text-sm font-black text-[${getColor(bottomCircleCur)}] w-full h-full flex items-center justify-center`}
            >
              {frontDef}
              {bottomCircleCur}
              {backDef}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
