import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export const RetailerCard = (props: any) => {
  const { circVal, retLogo, logoWidth } = props;

  return (
    <>
      <div className="flex items-center justify-center h-2/3 pt-8">
        <div className="absolute">
          <CircularProgress
            variant="determinate"
            value={100}
            size={100}
            thickness={5}
            sx={{
              color: '#f7f7f7',
              strokeLinecap: 'round',
            }}
          />
        </div>
        <div className="absolute">
          <CircularProgress
            variant="determinate"
            value={circVal}
            size={100}
            thickness={5}
            sx={{
              color: '#16A34A',
              strokeLinecap: 'round',
            }}
          />
        </div>
        <div className={`absolute text-2xl font-bold text-gray-900 flex items-center justify-center mb-2`}>
          {circVal}%
        </div>
      </div>
      <div className="flex items-center justify-center h-1/3 pb-2">
        {retLogo !== 'logo-allOthers.png' ? (
          <img src={`/logo-retailer/${retLogo}`} alt="logo walmart" style={{ maxWidth: `${logoWidth}px` }} />
        ) : (
          <div className="text-gray-500 text-md">All other retailers</div>
        )}
      </div>
    </>
  );
};
