import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import WorkSpaces from './WorkSpaces';
import MultiSelectMUI from '../MultiSelectMUI';
import { barrowsTheme, reckittTheme, zespriTheme } from '../Themes';

const NavLeft = (props: any) => {
  const {
    theme,
    setTheme,
    userEmail,
    hasTenantChanged,
    setHasTenantChanged,
    tenantLogo,
    setTenantLogo,
    tenantName,
    setTenantName,
    setTid,
    tId,
    selectedCampaign,
    setIsCampaignRendered,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isRegionOpen, setIsRegionOpen] = useState(true);
  const [isBrandGroupOpen, setIsBrandGroupOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState('insights');
  const [isActive, setIsActive] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tenantNames, setTenantNames] = useState([] as any);
  const [rawAccessData, setRawAccessData] = useState({} as any);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleIsRegionOpen = (event: any) => {
    // setIsRegionOpen(!isRegionOpen);
    // const element = event.target.innerHTML.split("<")[0];
    // element.style = "color: red";
    // console.log(event.target);
    setIsActive(!isActive);
    event.target.className === 'active' ? (event.target.className = '') : (event.target.className = 'active');
  };

  const handleIsBrandGroupOpen = () => {
    setIsBrandGroupOpen(!isBrandGroupOpen);
  };

  const handleSelected = (item: any) => {
    setSelectedItem(item);
    if (item === 'workspaces') {
      setIsExpanded(!isExpanded);
    } else {
      setIsExpanded(false);
    }
  };

  const handleTenantChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setTenantName(typeof value === 'string' ? value.split(',') : value);
    if (hasTenantChanged === 'false') {
      setHasTenantChanged('true');
    } else {
      setHasTenantChanged('false');
    }
    setTenantName(value);
  };

  return (
    <div
      className={`flex flex-col items-center h-full bg-white transition-all font-light ${isOpen ? 'min-w-[220px]' : 'min-w-[60px] sm:min-w-[80px]'}`}
    >
      <div
        className="box-border mt-5 mb-12 hover:text-blue-600 rounded-md transition-all w-full"
        onClick={handleIsOpen}
      >
        {isOpen ? (
          <div className="flex flex-row w-full justify-start ml-4 cursor-pointer">
            <CloseIcon />
            <div className="ml-1">Close</div>
          </div>
        ) : (
          <div className="cursor-pointer">
            <MenuIcon />
          </div>
        )}
      </div>
      {isOpen && tenantName && (
        <div className="flex justify-center items-center mb-2 -ml-0 sm:-ml-2">
          <MultiSelectMUI
            currValue={tenantName}
            label="Tenant"
            width={160}
            allValues={tenantNames}
            handleChange={handleTenantChange}
            size={'small'}
          />
        </div>
      )}
      <div className="flex flex-col w-full">
        <WorkSpaces
          isOpen={isOpen}
          isRegionOpen={isRegionOpen}
          isBrandGroupOpen={isBrandGroupOpen}
          selectedItem={selectedItem}
          handleSelected={handleSelected}
          isExpanded={isExpanded}
          isActive={isActive}
          handleIsRegionOpen={handleIsRegionOpen}
          handleIsBrandGroupOpen={handleIsBrandGroupOpen}
          theme={theme}
          setTheme={setTheme}
          reckittTheme={reckittTheme}
          barrowsTheme={barrowsTheme}
          zespriTheme={zespriTheme}
          tenantName={tenantName}
          setTenantName={setTenantName}
          setRawAccessData={setRawAccessData}
          rawAccessData={rawAccessData}
          setTenantNames={setTenantNames}
          tenantNames={tenantNames}
          userEmail={userEmail}
          hasTenantChanged={hasTenantChanged}
          setHasTenantChanged={setHasTenantChanged}
          tenantLogo={tenantLogo}
          setTenantLogo={setTenantLogo}
          setTid={setTid}
          tId={tId}
          selectedCampaign={selectedCampaign}
          setIsCampaignRendered={setIsCampaignRendered}
        />
      </div>
      <div
        className={`w-full flex flex-row py-4 transition-all cursor-pointer hover:bg-blue-50
        ${isOpen ? 'justify-start px-8' : 'justify-center px-4'}
        ${selectedItem === 'insights' && 'bg-blue-50 border-r-4'}`}
        onClick={() => handleSelected('insights')}
        style={{ borderColor: theme.palette.primary.light }}
      >
        <BarChartIcon />
        {isOpen && <div className="mx-2">Insights</div>}
      </div>
    </div>
  );
};

export default NavLeft;
