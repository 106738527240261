import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Pages/LoginPage';
import DashboardPageV2 from './Pages/DashboardPageV2';
import ActivatePage from './Pages/ActivatePage';
import ReturnEnv from './Components/User/Utilities';

const AppRoutes = () => {
  const [displayName, setDisplayName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');
  const [userClaim, setUserClaim] = React.useState('');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <LoginPage
              setDisplayName={setDisplayName}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              setUserClaim={setUserClaim}
            />
          }
        />
        <Route
          path="/dashboard"
          element={<DashboardPageV2 displayName={displayName} userEmail={userEmail} userClaim={userClaim} />}
        />
        <Route path="/activate" element={<ActivatePage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
