import { createTheme } from "@mui/material/styles";

export const barrowsTheme = createTheme({
  palette: {
    primary: {
      light: "#2563eb",
      main: "#111",
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f7f7f7",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

export const reckittTheme = createTheme({
  palette: {
    primary: {
      light: "#F0057C",
      main: "#111",
      dark: "#FF51A0",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f7f7f7",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

export const zespriTheme = createTheme({
  palette: {
    primary: {
      light: "#E82300",
      main: "#111",
      dark: "#B6D237",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f7f7f7",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

export const chartPalette = [
  "#16A34A",
  "#FB923C",
  "#9433EA",
  "#2663EB",
  "#FDE046",
  "#DC2625",
  "#bab0ab",
];
