import MetricNode from './MetricNode';
import NoData from './NoData';
import TwoSimplePieChart from './PieChart';
import { chartPalette } from './Themes';
import { CenterCircleNode } from './CenterCircleNode';
import BarChart from './BarChart';
import DataGridDemoMUI from './DataGridDemoMUI';
import GirlIcon from '@mui/icons-material/Girl';
import BoyIcon from '@mui/icons-material/Boy';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { RetailerCard } from './RetailerCard';
import { NumericFormat } from 'react-number-format';

export const CampaignTemplate02 = (props: any) => {
  const { selectedCampaign, isCampaignRendered } = props;

  return (
    <div className="grid mt-4 sm:mt-0">
      <div className="parent p-0 sm:p-4 mt-2">
        <div className="div1 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6">
          {isCampaignRendered && selectedCampaign['totalInvestment'] ? (
            <MetricNode
              title="Budget"
              frontDef={null}
              metricValue={Math.round(selectedCampaign['totalInvestment'] / 1000)}
              backDef={'K'}
              trending={'up'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          ) : (
            <NoData />
          )}
        </div>
        <div className="div2 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6">
          {isCampaignRendered && selectedCampaign['impressions'] ? (
            <MetricNode
              title="Impressions"
              frontDef={null}
              metricValue={(selectedCampaign['impressions'] / 1000000).toFixed(2)}
              backDef={'M'}
              trending={'up'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          ) : (
            <NoData />
          )}
        </div>
        <div
          className={`div3 bg-white rounded-xl shadow-md relative flex flex-col justify-center items-center overflow-hidden py-6 ${selectedCampaign.partnerFees ? '' : 'expand-cell'}`}
        >
          {isCampaignRendered && selectedCampaign['redemptionBudget'] ? (
            <MetricNode
              title="Redemption Budget"
              frontDef={null}
              metricValue={selectedCampaign['redemptionBudget'] / 1000}
              backDef={'K'}
              trending={'up'}
              size={'medium'}
              isCampaignRendered={isCampaignRendered}
            />
          ) : (
            <NoData />
          )}
        </div>
        {isCampaignRendered && selectedCampaign['partnerFees'] && (
          <div className="div4 bg-white rounded-xl shadow-md flex flex-row justify-center items-center relative">
            <div className="w-full flex items-center justify-center flex-col relative h-full">
              <MetricNode
                title="Partner Fees"
                frontDef={null}
                metricValue={selectedCampaign['partnerFees'] / 1000}
                backDef={'K'}
                trending={'up'}
                size={'medium'}
                isCampaignRendered={isCampaignRendered}
              />
            </div>
          </div>
        )}

        <div className="div5 bg-white flex justify-center items-center rounded-xl shadow-md relative py-6">
          {isCampaignRendered ? (
            <div className="w-full h-full flex flex-col xl:flex-row justify-center items-center">
              <div className="absolute top-3 left-4 text-sm text-gray-500">Impressions By Tactic</div>
              <div className="flex items-center justify-center sm:w-full md:w-full lg:w-full xl:w-1/2">
                <div className="absolute">
                  <div className="text-xl font-bold text-black mb-[-6px]">
                    {Math.round(selectedCampaign['impressions'] / 1000000)}M
                  </div>
                  <div className="text-[10px] text-gray-500">Impressions</div>
                </div>
                {selectedCampaign['donut'] && (
                  <TwoSimplePieChart data={selectedCampaign['donut']} palette={chartPalette} symbol={'%'} />
                )}
              </div>
              <div className="hidden lg:flex w-full md:w-[1px] h-2/3 bg-slate-200 my-4"></div>
              {selectedCampaign['donut'] && (
                <div className="flex flex-col w-full md:w-1/2 pl-12 sm:pl-6 lg:block xl:block">
                  {selectedCampaign['donut'].map((item: any, index: any) => {
                    return (
                      <div className="flex flex-row w-full my-1" key={index}>
                        <div>
                          <div
                            key={index}
                            className="w-[12px] h-[12px] bg-slate-200 m-[2px] rounded-[2px]"
                            style={{
                              background: chartPalette[index],
                            }}
                          ></div>
                        </div>
                        <div className="flex text-[10px] text-gray-500 w-2/4 justify-start ml-1">{item.label}</div>
                        <div className="flex flex-row items-center w-1/4 justify-end">
                          <div className="text-[10px] text-gray-500">
                            {/*{item.value}%*/}
                            <NumericFormat
                              value={item.value}
                              displayType="text"
                              // thousandsGroupStyle="lakh"
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
        <div className="div6 bg-white rounded-xl shadow-md flex p-8 items-center justify-center flex-col relative">
          {isCampaignRendered && (
            <div className="absolute top-3 left-4 text-sm text-gray-500">Performance Analysis</div>
          )}
          {isCampaignRendered ? (
            <CenterCircleNode
              topRightCircVal={selectedCampaign['topRightCircVal']}
              topRightCircCur={selectedCampaign['topRightCircCur']}
              mainCircVal={selectedCampaign['mainCircVal']}
              mainCircCur={selectedCampaign['mainCircCur']}
              mainCircTitle={'Redemption Rate'}
              bottomLeftCircVal={selectedCampaign['bottomLeftCircVal']}
              bottomCircleCur={selectedCampaign['bottomCircleCur']}
              rangeIndicators={false}
              frontDef={null}
              backDef={'%'}
            />
          ) : (
            <NoData />
          )}
          {isCampaignRendered ? (
            <div className="text-xs text-slate-500 -mb-8 mt-6 px-0 sm:px-12">
              <span className="text-5xl font-serif align-bottom inline-block mb-[-16px]">&ldquo;</span>
              {selectedCampaign['insight01']}
              <span className="text-5xl align-top ml-[2px] font-serif inline-block mt-1">&rdquo;</span>
            </div>
          ) : null}
        </div>
        <div className="div7 bg-white rounded-xl shadow-md flex px-8 items-center justify-center relative">
          {isCampaignRendered ? (
            <div className="text-xs text-slate-500 -mb-4">
              <span className="text-5xl font-serif align-bottom inline-block mb-[-16px]">&ldquo;</span>
              {selectedCampaign['insight02']}
              <span className="text-5xl align-top ml-[2px] font-serif inline-block mt-1">&rdquo;</span>
            </div>
          ) : (
            <NoData />
          )}
        </div>
        <div className="div8 bg-white rounded-xl shadow-md flex items-center justify-center relative">
          <div className="w-1/3 flex flex-col items-center justify-center relative h-full">
            {isCampaignRendered && selectedCampaign['redemptionRate'] ? (
              <MetricNode
                title="Overall Redemption Rate"
                frontDef={null}
                metricValue={selectedCampaign['redemptionRate']}
                backDef={'%'}
                trending={'down'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
          <div className="w-1/3 flex flex-col items-center justify-center relative h-full">
            {isCampaignRendered && selectedCampaign['followOnPurchases'] ? (
              <MetricNode
                title="Follow-on Purchases"
                frontDef={null}
                metricValue={selectedCampaign['followOnPurchases']}
                backDef={'%'}
                trending={'up'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
          <div className="w-1/3 flex flex-col items-center justify-center relative h-full">
            {isCampaignRendered && selectedCampaign['followOnUnitsPerTrip'] ? (
              <MetricNode
                title="Follow-on Units Per Trip"
                frontDef={null}
                metricValue={selectedCampaign['followOnUnitsPerTrip']}
                backDef={'%'}
                trending={'up'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="div9 bg-white rounded-xl shadow-md flex items-center justify-center relative">
          {isCampaignRendered && selectedCampaign['salesLift'] && (
            <div className="w-full flex flex-col items-center justify-center relative h-full">
              <MetricNode
                title="Sales Lift"
                frontDef={null}
                metricValue={selectedCampaign['salesLift']}
                backDef={'%'}
                trending={'down'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            </div>
          )}
          <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
          {selectedCampaign['postCamapignSalesLift'] && (
            <div className="w-full flex flex-col items-center justify-center relative h-full">
              {isCampaignRendered && selectedCampaign['postCamapignSalesLift'] ? (
                <MetricNode
                  title="Post Campaign Sales Lift"
                  frontDef={null}
                  metricValue={selectedCampaign['postCamapignSalesLift']}
                  backDef={'%'}
                  trending={'up'}
                  size={'flex'}
                  isCampaignRendered={isCampaignRendered}
                />
              ) : (
                <NoData />
              )}
            </div>
          )}
          <div className="w-[1px] h-2/3 bg-slate-200 my-4"></div>
          {isCampaignRendered && selectedCampaign['incrementalSales'] && (
            <div className="w-full flex flex-col items-center justify-center relative h-full">
              <MetricNode
                title="Daily Incremental Units Sold"
                frontDef={'+'}
                metricValue={Math.round(selectedCampaign['incrementalSales'] / 1000)}
                backDef={'K'}
                trending={'down'}
                size={'flex'}
                isCampaignRendered={isCampaignRendered}
              />
            </div>
          )}
        </div>
        <div className="div10 bg-white rounded-xl shadow-md flex pl-2 sm:px-8 items-center justify-center relative">
          <div className="absolute top-3 left-4 text-sm text-gray-500">Redemption Rate by User Type</div>
          {isCampaignRendered && selectedCampaign['bar'] ? (
            <BarChart palette={chartPalette} data={selectedCampaign['bar']} frontDef="" backDef="%" />
          ) : (
            <NoData />
          )}
        </div>
      </div>
      {selectedCampaign?.dataGrid && (
        <div className="hidden xl:block">
          <div className="flex w-full ml-4 mt-0 mb-1 sm:mb-6 sm:mt-2 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500">
            Tactic Detail
          </div>
          <div className="flex items-center justify-center shadow-md rounded-xl mx-4 mb-4 p-4 bg-white min-h-[320px] ">
            <DataGridDemoMUI data={selectedCampaign} />
          </div>
        </div>
      )}

      <div className="hidden lg:flex w-full ml-4 mt-0 mb-1 sm:mb-6 sm:mt-2 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500">
        Audience Analysis
      </div>

      <div className="hidden lg:flex items-center justify-center px-2 mb-4">
        <div className="flex items-center justify-center flex-col shadow-md rounded-xl w-2/12 h-full bg-white mx-2 py-8 min-h-[200px]">
          {isCampaignRendered && selectedCampaign['audienceFemale'] ? (
            <>
              <GirlIcon sx={{ fontSize: '160px', color: '#9433EA' }} className="-mt-4" />
              <div className="text-4xl font-bold text-[#9433EA]">{selectedCampaign['audienceFemale']}%</div>
              <div className="text-xs text-gray-500">Female</div>
            </>
          ) : (
            <NoData />
          )}
        </div>
        <div className="hidden lg:flex items-center justify-center flex-col shadow-md rounded-xl w-2/12 h-full bg-white mx-2 py-8 min-h-[200px]">
          {isCampaignRendered && selectedCampaign['audienceMale'] ? (
            <>
              <BoyIcon sx={{ fontSize: '160px', color: '#2663EB' }} className="-mt-4" />
              <div className="text-4xl font-bold text-[#2663EB]">{selectedCampaign['audienceMale']}%</div>
              <div className="text-xs text-gray-500">Male</div>
            </>
          ) : (
            <NoData />
          )}
        </div>
        <div
          className={`hidden lg:flex items-center justify-center shadow-md rounded-xl h-full bg-white mx-2 sm:px-8 sm:py-4 relative ${selectedCampaign.donutAudience ? 'w-5/12' : 'w-8/12'}`}
        >
          <div className="absolute top-3 left-4 text-sm text-gray-500">Redemption Rate by Age Tier</div>
          {isCampaignRendered && selectedCampaign['barAudience'] ? (
            <>
              <BarChart palette={chartPalette} data={selectedCampaign['barAudience']} frontDef="" backDef="%" />
            </>
          ) : (
            <NoData />
          )}
        </div>

        {selectedCampaign['donutAudience'] && (
          <div className="flex items-center flex-col justify-center shadow-md rounded-xl w-3/12 h-full bg-white mx-2 py-4 relative px-4">
            <div className="absolute top-3 left-4 text-sm text-gray-500">Redemption Rate by Audience</div>
            {isCampaignRendered && selectedCampaign['donutAudience'] ? (
              <div className="relative flex items-center justify-center mt-2">
                <div className="absolute">
                  <div className="text-xl font-bold text-black mb-[-6px]">
                    {Math.round(selectedCampaign['donutAudienceCenterValue'] / 1000000)}%
                  </div>
                  <div className="text-[10px] text-gray-500">{selectedCampaign?.donutAudienceCenterTitle}</div>
                </div>
                <TwoSimplePieChart data={selectedCampaign['donutAudience']} palette={chartPalette} symbol={'%'} />
              </div>
            ) : (
              <NoData />
            )}
            <div className="hidden w-full lg:flex md:h-[1px] bg-slate-200 mb-4 mt-2"></div>
            {isCampaignRendered && selectedCampaign['donutAudience'] ? (
              <div className="flex flex-row flex-wrap justify-center w-3/4 mt-1">
                {selectedCampaign['donutAudience'].map((item: any, index: any) => {
                  return (
                    <div className="flex items-center min-w-1/8 m-1" key={index}>
                      <div
                        key={index}
                        className="w-[12px] h-[12px] bg-slate-200 m-[2px] rounded-[2px]"
                        style={{
                          background: chartPalette[index],
                        }}
                      ></div>
                      <div className="flex text-[10px] text-gray-500 justify-start ml-[1px] mr-[4px]">{item.label}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        )}
      </div>
      {selectedCampaign?.retailerCards && (
        <div className="hidden xl:block">
          <div className="flex w-full ml-4 mt-0 mb-1 sm:mb-6 sm:mt-2 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500">
            Retailer Redemption Breakout
          </div>
          <div className={`grid grid-cols-7 gap-4 min-h-[200px] mx-4 mb-4`}>
            {selectedCampaign['retailerCards'].map((item: any, index: any) => {
              return (
                <div
                  className="flex items-center justify-center flex-col shadow-md rounded-xl h-full bg-white relative"
                  key={index}
                >
                  <RetailerCard
                    circVal={item['value']}
                    retLogo={`logo-${item['name']}.png`}
                    logoWidth={item['logoWidth']}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
